<template>
    <b-overlay :show='showLoader'>
        <form-component title="OBRAZEC ZA SPREMEMBO ŠTEVILA OSEB IN ŽIVALI" unique_id="form-1">
            <validation-observer ref="validate">
                <b-form>
                    <b-row>
                        <b-col cols="12" lg="7">
                            <b-form-group label="Naslov posamezne enote">
                                <validation-provider #default="{ errors }" name="naslov posamezne enote" rules="required">
                                    <b-form-input v-model="form.address_unit" placeholder="kraj, ulica, poštna št., številka/šifra posamezne enote"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group>
                        <legend>Posamezna enota se uporablja<span style="font-weight: normal; color: black"> (ustrezno označi)</span></legend>
                        <validation-provider #default="{ errors }" name="posamezna enota se uporablja" rules="required">
                            <b-row>
                                <b-col cols="12" sm="6" lg="4" class="mt-1" :key="index" v-for="(item, index) in unitTypes">
                                    <RadioField v-model="form.unit" name="unit" :value="item.name" :text="item.name" type="white"/>
                                </b-col>
                            </b-row>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-row>
                        <b-col cols="12" lg="7">
                            <b-form-group label="Naslov lastnika posamezne enote">
                                <validation-provider #default="{ errors }" name="naslov lastnika posamezne enote" rules="required">
                                    <b-form-input v-model="form.address_owner" placeholder="kraj, ulica, poštna št., naziv in sedež pravne osebe"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="7">
                            <b-form-group label="Lastnik ali najemnik">
                                <validation-provider #default="{ errors }" name="lastnik ali najemnik" rules="required">
                                    <b-form-input v-model="form.type" placeholder="ime, priimek, naziv in sedež pravne osebe"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group label="Izjava" class="izjava">
                        <a>Izjavljam, da posamezno enoto od: </a>
                        <span class="flat-input">
                            <flat-pickr class="form-control" v-model="form.date" :config="flatpickrConfig" placeholder="datum"/>
                            <FeatherIcon class="calendar-icon" icon="CalendarIcon" size="20" style="margin-top: -3px;"/>
                        </span>
                        <a style="margin-left: 12px"> uporablja </a>
                        <b-form-input style="width: 70px; height: 2rem; display: initial;" v-model="form.number_of_users" placeholder="št. uporabnikov"/>
                        <a> uporabnikov in da v posamezni enoti biva </a>
                        <b-form-input style="width: 70px; height: 2rem; display: initial;" v-model="form.number_of_animals" placeholder="št. živali"/>
                        <a> živali.</a>
                        <small v-if="error" class="text-danger d-block">Polje izjava je obvezno</small>
                    </b-form-group>
                    <b-form-group class="mt-2">
                        <p>S svojim podpisom jamčim za verodostojnost podatkov. Ta izjava se uporablja za obračun vseh obratovalnih stroškov, ki se delijo v večstanovanjski
                            stavbi, na podlagi merila delitve stroškov po številu uporabnikov.</p>
                    </b-form-group>

                    <rounded-button @click="validationForm">POŠLJI</rounded-button>
                </b-form>
            </validation-observer>
        </form-component>
    </b-overlay>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import {Slovenian} from 'flatpickr/dist/l10n/sl.js'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import FormComponent from './FormComponent.vue'

    import {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        VBTogglePlugin,
        BOverlay
    } from 'bootstrap-vue'
    import Vue from 'vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import RadioField from '@/views/Components/RadioField'
    Vue.use(VBTogglePlugin)
    export default {
        components: {
            flatPickr,
            RadioField,
            BRow,
            BCol,
            BForm,
            BFormGroup,
            BFormInput,
            BOverlay,
            ValidationProvider,
            ValidationObserver,
            RoundedButton,
            FormComponent },
        data() {
            return {
                showLoader: false,
                flatpickrConfig: { altInput: true, dateFormat: 'Z', altFormat: 'd. m. Y', wrap: true, locale: Slovenian, placeholder: 'datum'},
                form: {
                    address_unit: '',
                    unit: '',
                    address_owner: '',
                    type: '',
                    date: '',
                    number_of_users: '',
                    number_of_animals: ''
                },
                unitTypes: [{name: 'stanovanje, ki ga uporablja lastnik'}, {name: 'poslovni prostor'}, {name: 'sekundarno stanovanje'}, {name: 'stanovanje, ki je oddano v najem'}, {name: 'poslovni prostor, oddano v najem'}],
                error: false,
                required
            }
        },
        methods: {
            validationForm() {
                let ok = false
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        ok = true
                    }

                    if (this.form.date === '' || this.form.number_of_users === '' || this.form.number_of_animals === '') {
                        this.error = true
                    } else this.error = false

                    if (ok && !this.error) {
                        this.error = false
                        this.sendEmail()
                    }
                })
            },
            async sendEmail() {
                this.showLoader = true

                try {
                    const formData = new FormData()

                    let manager = null
                    if (this.$store.state.user.userData && this.$store.state.user.userData.manager !== '') {
                        if (this.$store.state.user.userData.manager === 'staninvest') manager = this.$store.state.app.managers[0]
                        else manager = this.$store.state.app.managers[1]
                    }

                    if (manager !== null && manager.email !== '') this.form.email_to = manager.email
                    else {
                        this.$printError('Ni dodan upravitelj')
                        return
                    }

                    formData.append('form', JSON.stringify(this.form))

                    await this.$http.post('/api/user/v1/send_mail/agent_message/form/1/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    this.$printSuccess('Email je bil uspešno poslan')
                } catch (error) {
                    this.$printError(`Pri pošiljanju emaila je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            }
        }
        
    }
</script>

<style scoped>
.izjava a {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.8;
}
</style>
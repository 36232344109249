var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('form-component',{attrs:{"title":"SEPA BREMENITEV - OTVORITEV, UKINITEV, SPREMEMBA","unique_id":"form-3"}},[_c('validation-observer',{ref:"validate"},[_c('b-form',[_c('b-form-group',[_c('p',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v("Vljudno vas prosimo, da ustrezno označite ali se želite prijaviti, odjaviti ali spremeniti podatke za prejemanje e-računa.")]),_c('validation-provider',{attrs:{"name":"prejemanje e-računa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',_vm._l((_vm.sepaTypes),function(item,index){return _c('b-col',{key:index,staticClass:"mt-1 pr-0",attrs:{"cols":"12","sm":"6","lg":"4"}},[(item.name === 'Ukinitev')?_c('span',[_c('RadioField',{attrs:{"name":"billType","value":item.name,"text":"Ukinitev, ki velja od ","type":"white"},model:{value:(_vm.form.sepa_type),callback:function ($$v) {_vm.$set(_vm.form, "sepa_type", $$v)},expression:"form.sepa_type"}}),_c('span',{staticClass:"flat-input"},[_c('flat-pickr',{attrs:{"config":_vm.flatpickrConfig,"placeholder":"datum"},model:{value:(_vm.form.cancel_from),callback:function ($$v) {_vm.$set(_vm.form, "cancel_from", $$v)},expression:"form.cancel_from"}}),_c('FeatherIcon',{staticClass:"calendar-icon",staticStyle:{"margin-top":"-3px"},attrs:{"icon":"CalendarIcon","size":"20"}})],1)],1):_c('RadioField',{attrs:{"name":"billType","value":item.name,"text":item.name,"type":"white"},model:{value:(_vm.form.sepa_type),callback:function ($$v) {_vm.$set(_vm.form, "sepa_type", $$v)},expression:"form.sepa_type"}})],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.error)?_c('small',{staticClass:"text-danger"},[_vm._v("Polje datum je obvezno")]):_vm._e()]}}])})],1),_c('p',{staticStyle:{"margin-top":"1rem","font-size":"18px","font-weight":"bold"}},[_vm._v("Podatki o plačniku")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Ime in priimek / Naziv podjetja"}},[_c('validation-provider',{attrs:{"name":"ime in priimek / naziv podjetja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"ime in priimek / naziv podjetja"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Naslov plačnika / sedež"}},[_c('validation-provider',{attrs:{"name":"naslov plačnika / sedež","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"kraj, ulica, poštna št. plačnika / sedež"},model:{value:(_vm.form.address_owner),callback:function ($$v) {_vm.$set(_vm.form, "address_owner", $$v)},expression:"form.address_owner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Številka plačilnega račun - IBAN"}},[_c('validation-provider',{attrs:{"name":"številka plačilnega račun - IBAN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"IBAN"},model:{value:(_vm.form.iban),callback:function ($$v) {_vm.$set(_vm.form, "iban", $$v)},expression:"form.iban"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Identifikacijska oznaka banke (SWIFT BIC)"}},[_c('validation-provider',{attrs:{"name":"identifikacijska oznaka banke (SWIFT BIC)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"SWIFT BIC"},model:{value:(_vm.form.bic),callback:function ($$v) {_vm.$set(_vm.form, "bic", $$v)},expression:"form.bic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Elektronski naslov za prejemanje e-računa"}},[_c('validation-provider',{attrs:{"name":"elektronski naslov za prejemanje e-računa","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"elektronski naslov"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Telefonska številka"}},[_c('validation-provider',{attrs:{"name":"telefonska številka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"telefonska številka"},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('p',{staticStyle:{"margin-top":"1rem","font-size":"18px","font-weight":"bold"}},[_vm._v("Podatki o stanovanju")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"naslov","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"kraj, ulica, poštna št."},model:{value:(_vm.form.address_unit),callback:function ($$v) {_vm.$set(_vm.form, "address_unit", $$v)},expression:"form.address_unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Številka oz. šifra posamezne enote"}},[_c('validation-provider',{attrs:{"name":"številka oz. šifra posamezne enote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"številka oz. šifra posamezne enote"},model:{value:(_vm.form.unit_number),callback:function ($$v) {_vm.$set(_vm.form, "unit_number", $$v)},expression:"form.unit_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',[_c('legend',[_vm._v("Oseba v imenu katere se vrši plačilo "),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("(Izpolnite v primeru, če ne gre za isto osebo, kot je plačnik)")])]),_c('b-form-input',{attrs:{"placeholder":"ime in priimek"},model:{value:(_vm.form.other_person),callback:function ($$v) {_vm.$set(_vm.form, "other_person", $$v)},expression:"form.other_person"}})],1),_c('b-form-group',{staticClass:"mt-2"},[_c('p',{staticClass:"font mb-0"},[_vm._v("Izjava prejemnika/plačnika računa:")]),_c('p',{staticClass:"mb-0"},[_vm._v("S podpisom soglašam, da mi izdajatelj, družba "),(_vm.$store.state.user.userData.manager)?_c('span',[_vm._v(_vm._s(_vm.$store.state.user.userData.manager.toUpperCase()))]):_vm._e(),_vm._v(" d.o.o., do odjave oz. preklica, vse račune, mesečne razdelilnike in s tem povezana pisanja, posreduje v elektronski obliki na elektronski naslov naveden v tej vlogi, s čimer veljavno nadomesti dokumente v papirni obliki. Seznanjen/a sem, da bo e-račun izdan v formatu XML in PDF. ")]),_c('p',[_vm._v("Seznanjen/a sem s Splošnimi pogoji prejemanja e-računov in se strinjam, da bom e-račun začel/a prejemati v naslednjem koledarskem mesecu od datuma, ko izdajatelj eračuna prejme izpolnjen in podpisan obrazec. S podpisom jamčim in zagotavljam, da so v vlogi navedeni podatki resnični in točni ter se zavezujem, da bom izdajatelju redno oz. najkasneje v 8 dneh od nastanka, sporočil/a morebitne spremembe podatkov.")])]),_c('rounded-button',{on:{"click":_vm.validationForm}},[_vm._v("POŠLJI")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('form-component',{attrs:{"title":"E-RAČUN - PRIJAVA, ODJAVA, SPREMEMBA","unique_id":"form-2"}},[_c('validation-observer',{ref:"validate"},[_c('b-form',[_c('b-form-group',[_c('p',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v(" Vljudno vas prosimo, da ustrezno označite ali se želite prijaviti, odjaviti ali spremeniti podatke za prejemanje e-računa. ")]),_c('validation-provider',{attrs:{"name":"prejemanje e-računa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',_vm._l((_vm.billTypes),function(item,index){return _c('b-col',{key:index,staticClass:"mt-1 pr-0",attrs:{"cols":"12"}},[_c('RadioField',{attrs:{"name":"billType","value":item.name,"text":item.name,"type":"white"},model:{value:(_vm.form.bill_type),callback:function ($$v) {_vm.$set(_vm.form, "bill_type", $$v)},expression:"form.bill_type"}})],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Ime in priimek / Naziv podjetja"}},[_c('validation-provider',{attrs:{"name":"ime in priimek / naziv podjetja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"ime in priimek / naziv podjetja"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Naslov enote"}},[_c('validation-provider',{attrs:{"name":"naslov enote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"kraj, ulica, poštna št."},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Šifra plačnika"}},[_c('validation-provider',{attrs:{"name":"šifra plačnika","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"šifra plačnika"},model:{value:(_vm.form.payer_code),callback:function ($$v) {_vm.$set(_vm.form, "payer_code", $$v)},expression:"form.payer_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Davčna številka"}},[_c('validation-provider',{attrs:{"name":"davčna številka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"davčna številka"},model:{value:(_vm.form.tax_number),callback:function ($$v) {_vm.$set(_vm.form, "tax_number", $$v)},expression:"form.tax_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Elektronski naslov za prejemanje e-računa"}},[_c('validation-provider',{attrs:{"name":"elektronski naslov za prejemanje e-računa","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"elektronski naslov"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Telefonska številka"}},[_c('validation-provider',{attrs:{"name":"telefonska številka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"telefonska številka"},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{staticClass:"mt-2"},[_c('p',{staticClass:"font mb-0"},[_vm._v("Izjava prejemnika/plačnika računa:")]),_c('p',{staticClass:"mb-0"},[_vm._v(" S podpisom soglašam, da mi izdajatelj, družba "),(_vm.$store.state.user.userData.manager)?_c('span',[_vm._v(_vm._s(_vm.$store.state.user.userData.manager.toUpperCase()))]):_vm._e(),_vm._v(" d.o.o., do odjave oz. preklica, vse račune, mesečne razdelilnike in s tem povezana pisanja, posreduje v elektronski obliki na elektronski naslov naveden v tej vlogi, s čimer veljavno nadomesti dokumente v papirni obliki. Seznanjen/a sem, da bo e-račun izdan v formatu XML in PDF. ")]),_c('p',[_vm._v(" Seznanjen/a sem s Splošnimi pogoji prejemanja e-računov in se strinjam, da bom e-račun začel/a prejemati v naslednjem koledarskem mesecu od datuma, ko izdajatelj eračuna prejme izpolnjen in podpisan obrazec. S podpisom jamčim in zagotavljam, da so v vlogi navedeni podatki resnični in točni ter se zavezujem, da bom izdajatelju redno oz. najkasneje v 8 dneh od nastanka, sporočil/a morebitne spremembe podatkov. ")])]),_c('rounded-button',{on:{"click":_vm.validationForm}},[_vm._v("POŠLJI")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
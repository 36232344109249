<template>
    <b-overlay :show="showLoader">

        <form-component title="E-RAČUN - PRIJAVA, ODJAVA, SPREMEMBA" unique_id="form-2">
            <validation-observer ref="validate">
                <b-form>
                    <b-form-group>
                        <p style="font-size: 18px; font-weight: bold">
                            Vljudno vas prosimo, da ustrezno označite ali se želite
                            prijaviti, odjaviti ali spremeniti podatke za prejemanje
                            e-računa.
                        </p>
                        <validation-provider
                            #default="{ errors }"
                            name="prejemanje e-računa"
                            rules="required"
                        >
                            <b-row>
                                <b-col
                                    cols="12"
                                    class="mt-1 pr-0"
                                    :key="index"
                                    v-for="(item, index) in billTypes"
                                >
                                    <RadioField
                                        v-model="form.bill_type"
                                        name="billType"
                                        :value="item.name"
                                        :text="item.name"
                                        type="white"
                                    />
                                </b-col>
                            </b-row>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Ime in priimek / Naziv podjetja">
                                <validation-provider
                                    #default="{ errors }"
                                    name="ime in priimek / naziv podjetja"
                                    rules="required"
                                >
                                    <b-form-input v-model="form.name" placeholder="ime in priimek / naziv podjetja" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Naslov enote">
                                <validation-provider
                                    #default="{ errors }"
                                    name="naslov enote"
                                    rules="required"
                                >
                                    <b-form-input v-model="form.address" placeholder="kraj, ulica, poštna št." />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Šifra plačnika">
                                <validation-provider
                                    #default="{ errors }"
                                    name="šifra plačnika"
                                    rules="required"
                                >
                                    <b-form-input v-model="form.payer_code" placeholder="šifra plačnika" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Davčna številka">
                                <validation-provider
                                    #default="{ errors }"
                                    name="davčna številka"
                                    rules="required"
                                >
                                    <b-form-input v-model="form.tax_number" placeholder="davčna številka" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-group
                                label="Elektronski naslov za prejemanje e-računa"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="elektronski naslov za prejemanje e-računa"
                                    rules="required|email"
                                >
                                    <b-form-input v-model="form.email" placeholder="elektronski naslov" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <b-form-group label="Telefonska številka">
                                <validation-provider
                                    #default="{ errors }"
                                    name="telefonska številka"
                                    rules="required"
                                >
                                    <b-form-input v-model="form.phone_number" placeholder="telefonska številka" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group class="mt-2">
                        <p class="font mb-0">Izjava prejemnika/plačnika računa:</p>
                        <p class="mb-0">
                            S podpisom soglašam, da mi izdajatelj, družba
                            <span v-if="$store.state.user.userData.manager">{{$store.state.user.userData.manager.toUpperCase()}}</span>
                            d.o.o., do odjave oz. preklica, vse račune, mesečne
                            razdelilnike in s tem povezana pisanja, posreduje v
                            elektronski obliki na elektronski naslov naveden v tej vlogi,
                            s čimer veljavno nadomesti dokumente v papirni obliki.
                            Seznanjen/a sem, da bo e-račun izdan v formatu XML in PDF.
                        </p>
                        <p>
                            Seznanjen/a sem s Splošnimi pogoji prejemanja e-računov in se
                            strinjam, da bom e-račun začel/a prejemati v naslednjem
                            koledarskem mesecu od datuma, ko izdajatelj eračuna prejme
                            izpolnjen in podpisan obrazec. S podpisom jamčim in
                            zagotavljam, da so v vlogi navedeni podatki resnični in točni
                            ter se zavezujem, da bom izdajatelju redno oz. najkasneje v 8
                            dneh od nastanka, sporočil/a morebitne spremembe podatkov.
                        </p>
                    </b-form-group>
                    <rounded-button @click="validationForm">POŠLJI</rounded-button>

                </b-form>
            </validation-observer>
        </form-component>
    </b-overlay>
</template>

<script>
    import {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        VBTogglePlugin,
        BOverlay
    } from 'bootstrap-vue'
    import Vue from 'vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import RadioField from '@/views/Components/RadioField'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import FormComponent from './FormComponent.vue'

    Vue.use(VBTogglePlugin)
    export default {
        components: {
            RadioField,
            BRow,
            BCol,
            BForm,
            BFormGroup,
            BFormInput,
            BOverlay,
            ValidationProvider,
            ValidationObserver,
            RoundedButton,
            FormComponent
        },
        data() {
            return {
                showLoader: false,
                form: {
                    bill_type: '',
                    name: '',
                    address: '',
                    payer_code: '',
                    tax_number: '',
                    email: '',
                    phone_number: ''
                },
                billTypes: [
                    { name: 'prijava na izdajo e-računa' },
                    { name: 'preklic prejemanje e-računa' },
                    { name: 'sprememba podatkov za prejemanje e-računa' }
                ],
                email,
                required
            }
        },
        methods: {
            validationForm() {
                this.$refs.validate.validate().then((success) => {
                    if (success) {
                        this.sendEmail()
                    }
                })
            },
            async sendEmail() {
                this.showLoader = true

                try {
                    const formData = new FormData()

                    let manager = null
                    if (this.$store.state.user.userData && this.$store.state.user.userData.manager !== '') {
                        if (this.$store.state.user.userData.manager === 'staninvest') manager = this.$store.state.app.managers[0]
                        else manager = this.$store.state.app.managers[1]
                    }

                    if (manager !== null && manager.email !== '') this.form.email_to = manager.email
                    else {
                        this.$printError('Ni dodan upravitelj')
                        return
                    }

                    formData.append('form', JSON.stringify(this.form))

                    await this.$http.post('/api/user/v1/send_mail/agent_message/form/2/', formData, {headers: { 'Content-Type': 'multipart/form-data' }})
                    this.$printSuccess('Email je bil uspešno poslan')
                } catch (error) {
                    this.$printError(`Pri pošiljanju emaila je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            if (this.$store.state.user.userData !== null) {
                this.form.email = this.$store.state.user.userData.email
                this.form.phone_number = this.$store.state.user.userData.telephone_number
            }
        }
    }
</script>

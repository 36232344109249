var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('form-component',{attrs:{"title":"OBRAZEC ZA SPREMEMBO ŠTEVILA OSEB IN ŽIVALI","unique_id":"form-1"}},[_c('validation-observer',{ref:"validate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"7"}},[_c('b-form-group',{attrs:{"label":"Naslov posamezne enote"}},[_c('validation-provider',{attrs:{"name":"naslov posamezne enote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"kraj, ulica, poštna št., številka/šifra posamezne enote"},model:{value:(_vm.form.address_unit),callback:function ($$v) {_vm.$set(_vm.form, "address_unit", $$v)},expression:"form.address_unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',[_c('legend',[_vm._v("Posamezna enota se uporablja"),_c('span',{staticStyle:{"font-weight":"normal","color":"black"}},[_vm._v(" (ustrezno označi)")])]),_c('validation-provider',{attrs:{"name":"posamezna enota se uporablja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',_vm._l((_vm.unitTypes),function(item,index){return _c('b-col',{key:index,staticClass:"mt-1",attrs:{"cols":"12","sm":"6","lg":"4"}},[_c('RadioField',{attrs:{"name":"unit","value":item.name,"text":item.name,"type":"white"},model:{value:(_vm.form.unit),callback:function ($$v) {_vm.$set(_vm.form, "unit", $$v)},expression:"form.unit"}})],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"7"}},[_c('b-form-group',{attrs:{"label":"Naslov lastnika posamezne enote"}},[_c('validation-provider',{attrs:{"name":"naslov lastnika posamezne enote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"kraj, ulica, poštna št., naziv in sedež pravne osebe"},model:{value:(_vm.form.address_owner),callback:function ($$v) {_vm.$set(_vm.form, "address_owner", $$v)},expression:"form.address_owner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"7"}},[_c('b-form-group',{attrs:{"label":"Lastnik ali najemnik"}},[_c('validation-provider',{attrs:{"name":"lastnik ali najemnik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"ime, priimek, naziv in sedež pravne osebe"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{staticClass:"izjava",attrs:{"label":"Izjava"}},[_c('a',[_vm._v("Izjavljam, da posamezno enoto od: ")]),_c('span',{staticClass:"flat-input"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatpickrConfig,"placeholder":"datum"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('FeatherIcon',{staticClass:"calendar-icon",staticStyle:{"margin-top":"-3px"},attrs:{"icon":"CalendarIcon","size":"20"}})],1),_c('a',{staticStyle:{"margin-left":"12px"}},[_vm._v(" uporablja ")]),_c('b-form-input',{staticStyle:{"width":"70px","height":"2rem","display":"initial"},attrs:{"placeholder":"št. uporabnikov"},model:{value:(_vm.form.number_of_users),callback:function ($$v) {_vm.$set(_vm.form, "number_of_users", $$v)},expression:"form.number_of_users"}}),_c('a',[_vm._v(" uporabnikov in da v posamezni enoti biva ")]),_c('b-form-input',{staticStyle:{"width":"70px","height":"2rem","display":"initial"},attrs:{"placeholder":"št. živali"},model:{value:(_vm.form.number_of_animals),callback:function ($$v) {_vm.$set(_vm.form, "number_of_animals", $$v)},expression:"form.number_of_animals"}}),_c('a',[_vm._v(" živali.")]),(_vm.error)?_c('small',{staticClass:"text-danger d-block"},[_vm._v("Polje izjava je obvezno")]):_vm._e()],1),_c('b-form-group',{staticClass:"mt-2"},[_c('p',[_vm._v("S svojim podpisom jamčim za verodostojnost podatkov. Ta izjava se uporablja za obračun vseh obratovalnih stroškov, ki se delijo v večstanovanjski stavbi, na podlagi merila delitve stroškov po številu uporabnikov.")])]),_c('rounded-button',{on:{"click":_vm.validationForm}},[_vm._v("POŠLJI")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
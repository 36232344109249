<template>
    <b-overlay :show='showLoader'>

        <!-- OBRAZCI -->
        <b-card>
            <h2 class="heading">Obrazci</h2>
            <form1 class="mt-2"/>
            <form2/>
            <form3/>
        </b-card>

        <!-- SPOROČILA UPRAVNIKU -->
        <b-card class="mb-1">
            <h2 class="heading">Sporočila upravniku</h2>
            <p>V kolikor imate vprašanje v zvezi z upravljanjem vaše stavbe ali želite oddati dokumente, uporabite spodnji obrazec.</p>
            
            <validation-observer ref="validate">
                <b-form class="form mt-2">
                    <b-form-group>
                        <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                            <b-form-input class="input" id="email" v-model="contact.email_from" placeholder="E-NASLOV"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <validation-provider #default="{ errors }" name="vrsta" rules="required">
                            <b-form-select v-model="contact.type_of_question" class="input">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Izberite vrsto</b-form-select-option>
                                </template>
                                <b-form-select-option :key="index" v-for="(option, index) in selectOptions" style="color: black" :value="option.value">{{option.text}}</b-form-select-option>
                            </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <validation-provider #default="{ errors }" name="sporočilo" rules="required">
                            <b-form-textarea class="input"  id="text" v-model="contact.message" rows="8" max-rows="8" placeholder="VAŠE SPOROČILO"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>
            <b-row class="mx-0">
                <b-col cols="12" md="9" class="pl-0">
                    <div class="add-files d-flex align-items-center" @click="$refs.file.click()">
                        <fa size="2x" icon="plus-circle" class="plus-circle-icon" />
                        <span class="ml-1">
                            PRIPNI DOKUMENTE
                        </span>
                        <input type="file" @change="fileChange" ref="file" style="display: none" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                    </div>
                    <b-row v-for="(item, index) of files" :key="'datoteka_'+index" align-v="center" class="mt-1 selected-file cursor-pointer" @click="removeFile(item)">
                        <b-col  cols="10">
                            {{item.name}}
                        </b-col>
                        <b-col cols="2" class="float-right">
                            <FeatherIcon class="float-right x-icon" icon="XIcon" size="20"/>
                        </b-col>
                    </b-row>
                </b-col>
                
                <b-col class="text-right mt-1 mt-md-0 pr-0" cols="12" md="3">
                    <rounded-button @click="validationForm">POŠLJI</rounded-button>
                </b-col>
            </b-row>

        </b-card>

    </b-overlay>
</template>
<script>
    import {BRow, BCol, BFormInput, BCard, BFormSelect, BFormSelectOption, BFormTextarea, BForm, BFormGroup, BOverlay} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import Form1 from '@/views/Pages/MyPortal/AgentMessagesForms/Form1'
    import Form2 from '@/views/Pages/MyPortal/AgentMessagesForms/Form2'
    import Form3 from '@/views/Pages/MyPortal/AgentMessagesForms/Form3'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    export default {
        components: {
            Form3,
            Form2,
            BRow,
            BCol,
            BFormInput,
            BCard,
            BFormSelect,
            BFormSelectOption,
            BFormTextarea,
            BForm,
            BFormGroup,
            BOverlay,
            Form1,
            ValidationProvider,
            ValidationObserver,
            RoundedButton
            // select
        },
        data() {
            return {
                showLoader: false,
                contact: {
                    email_from: '',
                    email_to: '',
                    message: '',
                    type_of_question: null
                },
                files: [],
                selectOptions: [
                    /*{ value: '1. Upravljanje', text: '1. Upravljanje' },
                    { value: '2.  Vzdrževanje', text: '2.  Vzdrževanje' },
                    { value: '3. Pravna služba', text: '3. Pravna služba' },
                    { value: '4. Nepremičninsko posredovanje', text: '4. Nepremičninsko posredovanje' },
                    { value: '5. Splošne informacije', text: '5. Splošne informacije' }*/
                    { value: '1. Reklamacija', text: '1. Reklamacija' },
                    { value: '2.  Pohvala/pripomba', text: '2.  Pohvala/pripomba' },
                    { value: '3. Splošne informacije', text: '3. Splošne informacije' }
                ],
                required,
                email
            }
        },
        methods: {
            validationForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.sendEmail()
                    }
                })
            },
            async sendEmail() {
                this.showLoader = true

                try {
                    const formData = new FormData()

                    let manager = null
                    if (this.$store.state.user.userData && this.$store.state.user.userData.manager !== '') {
                        if (this.$store.state.user.userData.manager === 'staninvest') manager = this.$store.state.app.managers[0]
                        else manager = this.$store.state.app.managers[1]
                    }
                    if (manager !== null && manager.email !== '') this.contact.email_to = manager.email
                    else {
                        this.$printError('Ni dodan upravitelj')
                        return
                    }

                    for (let i = 0; i < this.files.length; i++) {
                        const file = this.files[i]
                        formData.append('file', file)
                    }

                    formData.append('contact', JSON.stringify(this.contact))

                    await this.$http.post('/api/user/v1/send_mail/agent_message', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    this.$printSuccess('Email je bil uspešno poslan')

                } catch (error) {
                    this.$printError(`Pri pošiljanju emaila je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            fileChange(e) {
                if (e.target.files[0].type === 'application/pdf' || e.target.files[0].type === 'application/msword' || e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    this.files.push(e.target.files[0])
                } else this.$printError(`Datoteka tipa ${e.target.files[0].type} ni dovoljena. Dovoljene datoteke so: pdf, doc, docx.`)
            },
            removeFile(item) {
                this.files.splice(this.files.indexOf(item), 1)
                this.$refs.file.value = null
            }
        },
        mounted() {
            if (this.$store.state.user.userData !== null) {
                this.contact.email_from = this.$store.state.user.userData.email
            }
        }
    }
</script>

<style scoped>
.form-control:focus:valid, .form-control:focus.is-valid  {
    box-shadow: none !important;
}
.card-footer {
  padding: 0;
}
.card {
    box-shadow: none !important;
}
.heading {
    font-weight: bold;
    color: #72A5D8;
}
.input {
    background-color: #F4F4F4 !important;
    border: 1px solid #F4F4F4 !important;
    border-radius: 0 !important;
}
.input::placeholder{
    text-transform: uppercase;
    color: black;
}
.text-primary, .add-files {
    color: #72A5D8;
}
.add-files {
    cursor: pointer;
}
.add-files span {
    color: black;
    font-size: 0.9em;
}
.selected-file{
    background-color: #72A5D8;
    color: white;
    border-radius:42px; 
    padding-top: 0.4em;
    padding-bottom: 0.4em;
}
.x-icon  {
    color: white
}
.plus-circle-icon{
    font-size: 28px !important;
}
.selected-file {
    font-size: 1.2em;
}
.form textarea {
    color: black !important;
}
</style>
<template>
    <b-card no-body class="mb-1 form-component">
        <b-card-header header-tag="header" class="p-2" role="tab" >
            <a class="w-100" v-b-toggle :href="`#${unique_id}`" @click.prevent variant="grey">
                <b-row class="w-100 mx-0">
                    <b-col cols="10" md="11" class="d-flex flex-column justify-content-around">
                        <h3 class="mb-0 text-white text-center accordion-header">{{ title }} </h3>
                    </b-col>
                    <b-col cols="2" md="1" class="d-flex flex-column justify-content-around" align="right">
                        <h2 class="mb-0 text-white when-opened"><FeatherIcon icon="ChevronDownIcon" size="1x"/></h2>
                    </b-col>
                </b-row>
            </a>
        </b-card-header>
        <b-collapse :id="unique_id" accordion="my-accordion" role="tabpanel" class="filled-border-radius" style="background-color: #F3F3F3;">
            <b-card-body class="p-2">
                <slot></slot>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCollapse,
        BRow,
        BCol,
        VBTogglePlugin,
        BCardBody
    } from 'bootstrap-vue'
    import Vue from 'vue'
    Vue.use(VBTogglePlugin)
    export default {
        components: {
            BCard,
            BCardHeader,
            BCollapse,
            BRow,
            BCol,
            BCardBody },
        props: {
            title: {
                type: String,
                required: true
            },
            unique_id: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
.flat-input input.form-control {
  background-color: white;
  border: none;
  border-radius: 0;
  height: 2rem;
  display: inherit;
  width: 130px;
}
@media only screen and (min-width: 768px) {
    .accordion-header {
        font-size: 1.5em !important;
    }
}
.accordion-header {
    font-size: 1em;
}

.filled-border-radius {
    margin-top: -40px;
    padding-top: 40px;
    /* transition: 0.4s ease-in; */
}
.card .card-header {
    z-index: 99;
    background-color: #72A5D8 !important;
    border-radius: 50px !important;
    padding-top: 0.9em !important;
    padding-bottom: 0.9em !important;
}
.input {
  border-radius: 0;
  border: none;
}
.collapsed .when-opened svg {
  transform: rotate(-180deg);
}

.izjava a {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.8;
}
</style>
<style>
/* flatpickr calendar must be in global scope, otherwise style is not applied */
.form-component .flat-input input.form-control {
    height: 2rem;
    display: inherit;
    width: 130px;
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
}
.form-component .flat-input input.form-control:focus {
    background-color: #fff;
    border-color: #72A5D8 !important;
}
.form-component .calendar-icon {
  color: #009ED3 !important;
  margin-left: -28px;
}

/* form inputs can't be accessed in scoped style */
.form-component .form-group legend {
  color: #000000 !important;
  font-weight: bold;
  font-size: 18px;
}
.form-component .form-control:focus, .form-control:focus  {
    box-shadow: none !important;
}
</style>